import React, { useEffect } from "react";
import { useIntl } from "gatsby-plugin-intl";
import { Layout } from "../components/layout/layout";
import Seo from "../components/seo/seo";
import { SsyIntersectionObserver } from "../components/ssyIntersectionObserver/ssyIntersectionObserver";
import { RaisingBox } from "../components/raisingBox/raisingBox";
import { Feature } from "../components/feature/feature";
import style from "../assets/css/joinAsAdmin.module.css";
const JoinAsHostPage = (props) => {
  const intl = useIntl();
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (window.location.search) {
      window.location.href = window.location.origin + window.location.pathname;
    }
    if (/android/i.test(userAgent)) {
      window.location.assign(`${process.env.GATSBY_ANDROID_URL}`);
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.assign(
        `${process.env.GATSBY_IOS_URL}${intl.formatMessage({
          id: "MAIN_LANGUAGE",
        })}`
      );
    }
  }, []);

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: "JOIN_META_TITLE" })}
        lang={intl.formatMessage({ id: "MAIN_LANGUAGE" })}
        url={props.location.pathname}
        description={intl.formatMessage({ id: "JOIN_META_DESCRIPTION" })}
      />
      <section className={style.joinPage}>
        <SsyIntersectionObserver>
          <RaisingBox>
            <Feature image={"3-join-event"} title="JOIN_BY_SMARTPHONE" />
          </RaisingBox>
        </SsyIntersectionObserver>
      </section>
    </Layout>
  );
};

export default JoinAsHostPage;
